<template>
  <div class="min-h-100vh bg-hex-E3EEF8">
    <!-- 顶部 -->
    <div class="relative mb-neg_128">
      <!-- 背景 -->
      <img
        class="w-full"
        src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230705/FMHf0o7ST7dK6xipMKvx691NsBW5XjZa.png"
      />

      <!-- 内容 -->
      <div class="absolute left-0 top-0 w-full px-16">
        <div class="h-32"></div>

        <!-- <img
          class="h-24"
          src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/1T5D3pX9B1t8oQAycKN4JOq7gTSVCRNt.png"
        /> -->

        <div class="h-16"></div>

        <div class="text-24 font-bold">申请体验</div>

        <div class="h-12"></div>

        <div class="w-184 text-14 text-hex-666">
          填写表单后，我们的营销顾问 将会尽快联系
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 表单 -->
    <div class="relative mx-16">
      <!-- 背景-顶部 -->
      <img
        class="w-full"
        src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230705/LRJgTFB8JGiP1enFDHQ7pU9bbmkOfGU3.png"
      />

      <van-form
        ref="rulesForm"
        class="bg-white mx-4"
        label-width="auto"
        @submit="submit"
      >
        <div class="text-18 font-bold text-center leading-none pt-24 pb-20">
          申请信息填写
        </div>

        <!-- 项 -->
        <van-field
          label="公司名称"
          v-model="form.company"
          placeholder="请输入"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="rules.company"
        />

        <!-- 项 -->
        <van-field
          label="您的姓名"
          v-model="form.name"
          placeholder="请输入"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="rules.name"
        />

        <!-- 项 -->
        <van-field
          label="联系手机号"
          v-model="form.mobile"
          placeholder="请输入"
          maxlength="11"
          clearable
          input-align="right"
          error-message-align="right"
          :rules="rules.mobile"
        />

        <!-- 项 -->
        <van-field
          label="短信验证码"
          v-model="form.smsCode"
          placeholder="请输入"
          maxlength="6"
          clearable
          input-align="right"
          :rules="rules.smsCode"
        >
          <span
            slot="button"
            class="text-14 text-hex-648DFF"
            @click="getSmsCode()"
            >{{
              countdownSeconds ? `${countdownSeconds}s后重试` : "获取验证码"
            }}</span
          >
        </van-field>

        <!-- 提交 -->
        <div class="flex justify-center">
          <div
            class="w-200 text-16 text-white text-center font-bold leading-none bg_lin_gra_180_deg_66C2FE_4289F6_3065FF rounded-24 py-12 mt-24"
            @click="submit()"
          >
            免费申请
          </div>
        </div>

        <!-- 留白 -->
        <div class="h-32"></div>

        <!--  -->
      </van-form>

      <!-- 背景-顶部 -->
      <img
        class="w-full"
        src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230705/29X0463j4zqQWCkhOyIkf2e5evfM4pW0.png"
      />

      <!--  -->
    </div>

    <!-- 留白 -->
    <div class="h-64"></div>

    <!--  -->
  </div>
</template>
<script>
import qs from "qs";
import { isMobile } from "@/utils/tool";

import { recommendCustomCreatePost, recommendCustomSendSmsCode } from "@/api";

export default {
  data() {
    return {
      form: {},

      countdownSeconds: 0,

      //
    };
  },
  computed: {
    rules: () => ({
      /* name: [
        { required: true, message: "请输入" },
        {
          validator: (v) => !!v.trim(),
          message: "不允许只是空格",
        },
      ],
      company: [
        { required: true, message: "请输入" },
        {
          validator: (v) => !!v.trim(),
          message: "不允许只是空格",
        },
      ],
      mobile: [
        { required: true, message: "请输入" },
        {
          validator: (v) => isMobile(v),
          message: "请输入正确的手机号",
        },
      ],
      smsCode: [
        { required: true, message: "请输入" },
        {
          validator: (v) => !!v.trim(),
          message: "不允许只是空格",
        },
      ], */
      //
    }),

    //
  },
  created() {},
  destroyed() {
    clearTimeout(this.countdownTimeout);
  },
  methods: {
    // 提交
    async submit() {
      try {
        // await this.$refs["rulesForm"].validate();
        let { query } = this.$route;
        let urlObj = {};
        if (query.__origin__) {
          urlObj = qs.parse(query.__origin__);
        }
        if (!this.form.company?.trim()) throw "请输入公司名称";
        if (!this.form.name?.trim()) throw "请输入您的姓名";
        if (!isMobile(this.form.mobile)) throw "请输入正确的联系手机号";
        if (!this.form.smsCode?.trim()) throw "请输入短信验证码";

        this.$toast.loading({ duration: 0, forbidClick: true });

        let merchantName = decodeURIComponent(urlObj.merchantName);
        let applySource = decodeURIComponent(urlObj.applySource);

        await recommendCustomCreatePost({
          creator: this.$route.query.userId,
          customCompany: this.form.company,
          customMobile: this.form.mobile,
          customName: this.form.name,
          tenantName: this.$route.query.merchantName || merchantName,
          tenantNo: this.$route.query.merchantNo || urlObj.merchantNo,
          smsCode: this.form.smsCode,
          applySource: this.$route.query.applySource || applySource,
        });

        this.$router.replace(`/applyTrial/success`);

        //
      } catch (err) {
        if (Array.isArray(err)) return;
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }

      //
    },

    // 倒计时
    countdown() {
      if (this.countdownSeconds <= 0) return;
      this.countdownTimeout = setTimeout(() => {
        this.countdownSeconds--;
        this.countdown();
      }, 1000);
    },

    // 获取验证码
    async getSmsCode() {
      if (this.countdownSeconds > 0) return;

      try {
        if (!isMobile(this.form.mobile)) throw "请输入正确的手机号";

        this.$toast.loading({ duration: 0, forbidClick: true });

        await recommendCustomSendSmsCode(this.form.mobile);

        this.countdownSeconds = 60;
        this.countdown();

        //
      } catch (err) {
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }

      //
    },

    //
  },
};
</script>
<style lang="scss" scoped>

/deep/.van-field__label::after {
  position: absolute;
  right: -16px;
  color: #ee0a24;
  font-size: 14px;
  content: "*";
}
/deep/.van-field__label {
  position: relative;
}
/deep/.van-field__control,
/deep/.van-field__error-message {
  line-height: 1;
}
/deep/.van-cell {
  background: none;
  font-size: 28px;
  line-height: 40px;
  padding: 32px;
}

</style>
